export const registrationFields = {
  2: [
    {
      name: 'university',
      label: 'Название учебного заведения',
      required: true,
    },
    {
      name: 'gradeСlass',
      label: 'Класс',
      required: true,
    },
    {
      name: 'parentsContact',
      label: 'Контакты родителя (опекуна)',
      required: true,
    },
  ],
  3: [
    {
      name: 'gradeDegree',
      label: 'Степень',
      required: true,
      type: 'select',
      options: [
        {
          value: 'СПО',
          label: 'СПО',
        },
        {
          value: 'Бакалавриат',
          label: 'Бакалавриат',
        },
        {
          value: 'Магистратура',
          label: 'Магистратура',
        },
        {
          value: 'Аспирантура',
          label: 'Аспирантура',
        },
        {
          value: 'Специалитет',
          label: 'Специалитет',
        },
      ],
    },
    {
      name: 'university',
      label: 'Название учебного заведения',
      required: true,
    },
  ],
  4: [],
  5: [],
  6: [],
}
