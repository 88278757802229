import React, { useState, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import cn from 'classnames'
import classes from '@/components/css/Form.module.css'
import Layout from '@/layouts/MainLayout'
import request from '@/services/request'
import { useDispatch, useSelector } from 'react-redux'
import { tryLogin } from '@/store/actions/app'
import { Button, CustomField, Radio } from '@/components'
import { registrationFields } from '@/constants/registrationFields'
import { navigate } from 'gatsby'
import {
  DEFAULT_FIELDNAMES_REG,
  GRADE,
  GRADE_CLASS,
  GRADE_DEGREE,
  PRIVACY_POLICY,
} from '../services/constans'

function Registration({ location }) {
  const [isDisabled, setIsDisabled] = useState(false)
  const [status, setStatus] = useState(2)
  const dispatch = useDispatch()
  const { isAuth } = useSelector(state => state.app)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('status')) setStatus(searchParams.get('status'))
  }, [])

  useEffect(() => {
    if (isAuth) navigate('/profile/')
  }, [isAuth])

  async function onSubmit(data) {
    if (!data.status) data.status = status
    const errors = validate(data)
    if (errors) {
      return errors
    }
    data.status = +data.status
    data = {
      ...formatRegistrationData(data),
    }
    setIsDisabled(true)
    try {
      await request({
        method: 'POST',
        url: process.env.GATSBY_APP_TARGET + '/api/users',
        data,
      })
      const dataForLogin = {
        username: data.email,
        password: data.plainPassword,
      }
      dispatch(tryLogin(dataForLogin))
      navigate('/profile/')
    } catch (e) {
      console.error(e)
      if (e.response.data.detail) {
        const serverErrors = e.response.data.detail.split(/\n/gi)
        const errors = {}
        for (const error of serverErrors) {
          const [title, value] = error.split(':')
          errors[title] = value
        }
        return Object.keys(errors).length ? errors : undefined
      }
    } finally {
      setIsDisabled(false)
    }
  }

  function formatRegistrationData(data) {
    let params = {}
    let nameFields = [...DEFAULT_FIELDNAMES_REG]
    const { status } = data
    const additionalFields = registrationFields[status]?.map(
      field => field.name
    )
    nameFields = [...nameFields, ...additionalFields]
    Object.entries(data).forEach(([key, value]) => {
      const isUsed = nameFields.some(field => field === key)
      const isGrade = key === GRADE_CLASS || key === GRADE_DEGREE
      if (isUsed) {
        params = {
          ...params,
          [isGrade ? GRADE : key]: value,
        }
      }
    })
    return params
  }

  function validate(values) {
    const errors = {}
    if (values.plainPassword !== values.repeatPassword) {
      errors.repeatPassword = 'Пароли не совпадают'
    }

    if (!values.fullName?.trim()) {
      errors.fullName = 'Необходимо заполнить это поле'
    }
    if (!values.email?.trim()) {
      errors.email = 'Необходимо заполнить это поле'
    } else {
      const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      if (!regex.test(values.email.trim())) {
        errors.email = 'Это не похоже на настоящий email'
      }
    }
    if (!values.phone?.trim()) {
      errors.phone = 'Необходимо заполнить это поле'
    } else if (values.phone.indexOf('_') >= 0) {
      errors.phone = 'Некорректный номер телефона'
    }
    if (!values.plainPassword?.trim()) {
      errors.plainPassword = 'Необходимо заполнить это поле'
    } else if (values.plainPassword.length < 8) {
      errors.plainPassword = 'В пароле должно быть минимум 8 символов'
    }
    if (!values.repeatPassword?.trim()) {
      errors.repeatPassword = 'Необходимо заполнить это поле'
    } else if (values.repeatPassword.length < 8) {
      errors.repeatPassword = 'В пароле должно быть минимум 8 символов'
    }
    if (registrationFields[values.status])
      registrationFields[values.status].forEach(field => {
        if (field.required && !values[field.name]?.trim()) {
          errors[field.name] = 'Необходимо заполнить это поле'
        }
      })

    return Object.keys(errors).length ? errors : undefined
  }

  return (
    <Layout>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => {
          const currentStatus = values.status || status
          return (
            <form onSubmit={handleSubmit} autoComplete='off'>
              <h1 className={classes.h1}>Регистрация</h1>
              <div className={classes.roles}>
                <Field name='status'>
                  {props => (
                    <Radio
                      {...props.input}
                      value={2}
                      id='school'
                      text='Школьник'
                      checked={currentStatus == 2}
                    />
                  )}
                </Field>
                <Field name='status'>
                  {props => (
                    <Radio
                      {...props.input}
                      value={3}
                      id='student'
                      text='Студент'
                      checked={currentStatus == 3}
                    />
                  )}
                </Field>
                <Field name='status'>
                  {props => (
                    <Radio
                      {...props.input}
                      value={4}
                      id='parent'
                      text='Родитель'
                      checked={currentStatus == 4}
                    />
                  )}
                </Field>
                <Field name='status'>
                  {props => (
                    <Radio
                      {...props.input}
                      value={5}
                      id='master'
                      text='Специалист'
                      checked={currentStatus == 5}
                    />
                  )}
                </Field>
                <Field name='status'>
                  {props => (
                    <Radio
                      {...props.input}
                      value={6}
                      id='other'
                      text='Другое'
                      checked={currentStatus == 6}
                    />
                  )}
                </Field>
              </div>

              <div className={classes.inputWrapper}>
                <Field name='fullName'>
                  {props => (
                    <CustomField
                      {...props.input}
                      {...props.meta}
                      label='Фамилия Имя Отчество'
                      required
                    />
                  )}
                </Field>
              </div>
              <div className={classes.inputWrapper}>
                <Field name='email'>
                  {props => (
                    <CustomField
                      {...props.input}
                      {...props.meta}
                      label='E-mail'
                      required
                    />
                  )}
                </Field>
              </div>
              <div className={classes.inputWrapper}>
                <Field name='phone'>
                  {props => (
                    <CustomField
                      {...props.input}
                      {...props.meta}
                      label='Номер телефона'
                      required
                      type='tel'
                    />
                  )}
                </Field>
              </div>
              {registrationFields[currentStatus]?.map(field => (
                <div key={field.label} className={classes.inputWrapper}>
                  <Field name={field.name}>
                    {props => (
                      <CustomField
                        {...props.meta}
                        {...props.input}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              ))}
              <div className={classes.inputWrapper}>
                <Field name='plainPassword'>
                  {props => (
                    <CustomField
                      {...props.input}
                      {...props.meta}
                      label='Пароль'
                      type='password'
                      autocomplete='new-password'
                      required
                    />
                  )}
                </Field>
              </div>
              <div className={classes.inputWrapper}>
                <Field name='repeatPassword' component='input'>
                  {props => (
                    <CustomField
                      {...props.input}
                      {...props.meta}
                      label='Повторите пароль'
                      type='password'
                      required
                    />
                  )}
                </Field>
              </div>
              <div className={classes.inputWrapper}>
                <Field name='processingPersonalData' component='input'>
                  {props => (
                    <div className={classes.checkbox}>
                      <input
                        {...props.input}
                        {...props.meta}
                        type='checkbox'
                        defaultChecked
                        required
                        onChange={e => {
                          if (!e.target.checked) {
                            setIsDisabled(true)
                          } else {
                            setIsDisabled(false)
                          }
                          props.input.onChange(
                            'processingPersonalData',
                            e.target.checked
                          )
                        }}
                      />
                      <label className={classes.info}>
                        Я согласен с{' '}
                        <a
                          className={classes.info__link}
                          href={PRIVACY_POLICY}
                          target='_blank'
                        >
                          Политика конфидинциальности
                        </a>
                      </label>
                    </div>
                  )}
                </Field>
              </div>
              <div className={cn(classes.inputWrapper, classes.buttonWrapper)}>
                <Button
                  theme='primary'
                  size='large'
                  type='submit'
                  disabled={isDisabled}
                >
                  Регистрация
                </Button>
              </div>
            </form>
          )
        }}
      />
    </Layout>
  )
}

export default Registration
